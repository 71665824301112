import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

import mansurProfile from '../../images/mansur-profile.jpeg'

const QuemSomos = () => {
  return (
    <Layout>
      <SEO
        title="Quem Somos"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/quem-somo`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/about-us`}
      />

      <div className="about-us">
        <h1>Quem somos</h1>

        <div className="about-us-profile">
          <div
            className="about-us-profile-pic"
            style={{
              background: `url(${mansurProfile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="about-us-profile-content">
            <h3>Mansur Peixoto</h3>
            <span>Gestor de Conteúdo e Redes Sociais</span>
            <p>
              Empreendedor, influenciador digital e produtor de conteúdo com
              impacto em mais de 1 milhão de pessoas por mês. Estudante da
              história e religião Islâmica, falante de árabe, inglês e espanhol.
              Estudou a religião islâmica e árabe no Egito e continua seus
              estudos no Brasil.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default QuemSomos
